<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Novo funcionário</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="fname">Nome:</label>
                                    <input 
                                        type="text" 
                                        v-model="name" 
                                        class="form-control"
                                        :class="`form-control ${$v.name.$error ? 'is-invalid' : ''}`" 
                                        id="name" 
                                        placeholder="Nome"
                                        @input="$v.name.$touch()" 
                                        @blur="$v.name.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="lname">Cargo:</label>
                                    <input 
                                        type="text" 
                                        v-model="role" 
                                        class="form-control" 
                                        :class="`form-control ${$v.role.$error ? 'is-invalid' : ''}`" 
                                        id="role" 
                                        placeholder="Cargo"
                                        @input="$v.role.$touch()" 
                                        @blur="$v.role.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="add1">Email:</label>
                                    <input 
                                        class="floating-input form-control" 
                                        :class="`form-control ${$v.email.$error ? 'is-invalid' : ''}`" 
                                        v-model="email" 
                                        type="email" 
                                        placeholder="Email" 
                                        @input="$v.email.$touch()" 
                                        @blur="$v.email.$touch()"
                                    >
                                </div>
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Adicionar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
   name:'HumanResourcesAdd',
   mixins: [validationMixin],
   validations: {
        name: { required },
        role: { required },
        email:{ required , email }
    },
    data() {
        return {
            name: '',
            role: '',
            email: ''
        }
    },
    methods:{
        onSubmit()
        {
            let employees = JSON.parse(localStorage.getItem('employees'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                employees.push({Nome: this.name, Cargo: this.role, Email: this.email})
                localStorage.setItem('employees', JSON.stringify(employees));
                this.$router.push({name:'app.employees-list'})
            }
        }
    }

}
</script>